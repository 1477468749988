import "./App.css";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import "./firebase";

const provider = new OAuthProvider("oidc.bilar");

function App() {
  const onClick = async () => {
    const cred = await signInWithPopup(getAuth(), provider);
    console.log(OAuthProvider.credentialFromResult(cred)?.accessToken);
  };

  return (
    <div>
      <h1 className="display-1">Förmån Finans</h1>
      <button type="button" onClick={onClick}>
        Login
      </button>
    </div>
  );
}

export default App;
