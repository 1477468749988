// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBiCM0BybZSuFCnZAmm3d-k-Cfn2SOP4D8",
  authDomain: "bilar-4faf9.firebaseapp.com",
  projectId: "bilar-4faf9",
  storageBucket: "bilar-4faf9.firebasestorage.app",
  messagingSenderId: "716653437663",
  appId: "1:716653437663:web:f2b7cd0ab3c73dfc1cff6a",
};

// Initialize Firebase
initializeApp(firebaseConfig);
